@import 'colors.scss';

button.mat-mdc-unelevated-button:not(:disabled) {
    background-color: $coral;
    color: $white;
    width: 150px;
    border-radius: 10px;
    box-shadow: none;
    height: 50px;

    &.non-colored {
        background-color: transparent;
        color: $coral;
        border: $coral 1px solid;
    }
}
