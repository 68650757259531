@import 'colors.scss';

.mat-mdc-tab-header {
    background: $brown;
    padding: 3px;
    border-radius: 5px;
    width: 694px;
    align-self: center;
}

.mat-mdc-tab-label-container {
    border: none !important;
}

.mat-mdc-tab.mdc-tab {
    flex-grow: 1 !important;
}

.mdc-tab--active {
    background: $background-color !important;
    border-radius: 5px;
    padding: 0px 8px 0px 8px;
}

.mdc-tab-indicator {
    display: none !important;
}

.mat-mdc-tab-group {
    gap: 24px;
}
