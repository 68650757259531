@use '@angular/material' as mat;
@include mat.core();

@import 'app/styles/button.scss';
@import 'app/styles/tab-group.scss';
@import 'app/styles/colors.scss';

$cosign4me-admin-theme: mat.define-theme((color: (theme-type: light,
        primary: mat.$red-palette,
      ),
      density: (scale: 0,
      )));

:root {
  @include mat.all-component-themes($cosign4me-admin-theme);
}

@font-face {
  font-family: 'MabryPro';
  src: url('app/assets/fonts/MabryPro-Regular.ttf')
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: MabryPro !important
}

body {
  background-color: $background-color;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  justify-content: center;
}

p {
  margin: 0;
  color: $graphit;
  font-size: 16px;
}
